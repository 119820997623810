import React, { ReactElement } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { Button, Cancel } from '@novozymes/components';
import { useTranslation } from 'react-i18next';
import { TooltipRenderProps } from 'react-joyride';
import { TourStep } from 'utils/tourSteps';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '1.25rem',
    padding: '2rem 1.5rem 1.5rem 1.5rem',
    backgroundColor: theme.palette.background.paper,
    width: '20.125rem',
    position: 'relative',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
  },
  title: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    marginBottom: '1.5rem',
  },
  description: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    paddingBottom: '0.75rem',
  },
  cancelButton: {
    width: '0.75rem',
    height: '0.75rem',
    cursor: 'pointer',
    top: '1rem',
    right: '1rem',
    position: 'absolute',
  },
  buttonWrapper: {
    margin: '0 6px',
  },
}));

interface FormattedDescriptionProps {
  description: string;
  keyword: string;
  className: string;
}

export const FormattedDescription = (props: FormattedDescriptionProps): ReactElement => {
  const { description, keyword, className } = props;
  const splitStr = description.split(keyword);
  return (
    <Typography className={className}>
      {splitStr[0]} <strong> {keyword} </strong> {splitStr[1]}
    </Typography>
  );
};

interface TooltipProps extends TooltipRenderProps {
  step: TourStep;
}

const Tooltip = ({
  continuous,
  index,
  step,
  backProps,
  primaryProps,
  tooltipProps,
  closeProps,
  size,
}: TooltipProps): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation('intro');

  return (
    <Box data-testId="intro-tooltip" className={classes.container} {...tooltipProps}>
      <Cancel className={classes.cancelButton} {...closeProps} />
      {<Typography className={classes.title}>{step.title}</Typography>}
      {step.content.keyword ? (
        <FormattedDescription
          description={step.content.description}
          keyword={step.content.keyword}
          className={classes.description}
        />
      ) : (
        <Typography className={classes.description}>{step.content.description}</Typography>
      )}
      <Typography className={classes.description}>
        {t('tooltip.step')} {index + 1}/{size}
      </Typography>
      <Box className={classes.buttonContainer}>
        {index > 0 && (
          <Box className={classes.buttonWrapper}>
            <Button type="tertiary" {...backProps}>
              {t('tooltip.back-button')}
            </Button>
          </Box>
        )}
        {continuous && index + 1 !== 4 && (
          <Box className={classes.buttonWrapper}>
            <Button type="tertiary" {...primaryProps}>
              {t('tooltip.next-button')}
            </Button>
          </Box>
        )}
        {continuous && index + 1 === 4 && (
          <Box className={classes.buttonWrapper}>
            <Button type="tertiary" {...primaryProps}>
              {t('tooltip.finish-button')}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Tooltip;
