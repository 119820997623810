import React, { ReactElement, ReactNode, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const Redirecter = ({ redirectTo, children }: { redirectTo?: string; children: ReactNode }): ReactElement => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (redirectTo) {
      const path = location.pathname;
      if (path !== redirectTo) {
        history.replace(redirectTo);
      }
    }
  }, [redirectTo]);

  return <>{children}</>;
};

export default Redirecter;
