import React from 'react';
import { makeStyles, Box, Hidden } from '@material-ui/core';
import Particles, { IParticlesParams } from 'react-particles-js';
import { WithChildren } from 'types';
import TechnicalHelpContact from 'components/TechnicalHelpContact';

const params: IParticlesParams = {
  particles: {
    number: {
      value: 30,
      density: {
        enable: false,
      },
    },
    size: {
      value: 3,
      random: true,
      anim: {
        speed: 3,
        size_min: 0.1,
      },
    },
    opacity: {
      value: 0.4,
      anim: {
        speed: 0.5,
        minimumValue: 0,
      },
    },
    line_linked: {
      enable: false,
    },
    move: {
      random: true,
      speed: 0.5,
      direction: undefined,
      out_mode: undefined,
    },
  },
  interactivity: {
    events: {
      onclick: {
        enable: true,
        mode: 'repulse',
      },
    },
    modes: {
      repulse: {
        distance: 400,
        duration: 2,
      },
    },
  },
};

const useStyles = makeStyles(() => ({
  container: {
    padding: '0 2rem 6rem 2rem',
    margin: 0,
    backgroundImage: `url('./portal_bg_picture1.png')}`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundAttachment: 'fixed',
    flex: 1,
    position: 'relative',
  },
  noBgContainer: {
    padding: '1rem 2rem 0rem 2rem',
    margin: 0,
    position: 'relative',
  },
  content: {
    maxWidth: '98.5rem',
    margin: '0 auto',
  },
  particles: {
    position: 'absolute',
    top: '7.5rem',
    left: '0',
    width: '100%',
    height: '90%',
  },
}));

type BodyProps = WithChildren<{
  hideBackground?: boolean;
  disableCursor?: boolean;
}>;

const Body = (props: BodyProps) => {
  const { children, hideBackground, disableCursor } = props;
  const classes = useStyles();

  const cursor = document.getElementById('defaultCursor') as HTMLElement;

  const handleMouseMove = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    cursor.style.left = `${e.pageX}px`;
    cursor.style.top = `${e.pageY}px`;
    cursor.style.transition = 'none';
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    cursor.style.width = '2rem';
    cursor.style.height = '2rem';
    cursor.style.left = `${e.pageX}px`;
    cursor.style.top = `${e.pageY}px`;
    cursor.style.transition = 'all 0.2s ease';
  };

  const handleMouseUp = () => {
    cursor.style.width = '3rem';
    cursor.style.height = '3rem';
    cursor.style.transition = 'all 0.2s ease';
  };

  const handleMouseLeave = () => {
    cursor.style.visibility = 'hidden';
  };

  const handleMouseEnter = () => {
    cursor.style.visibility = 'visible';
  };

  return (
    <Box
      className={hideBackground ? classes.noBgContainer : classes.container}
      onMouseMove={disableCursor ? undefined : handleMouseMove}
      onMouseDown={disableCursor ? undefined : handleMouseDown}
      onMouseUp={disableCursor ? undefined : handleMouseUp}
      onMouseLeave={disableCursor ? undefined : handleMouseLeave}
      onMouseEnter={disableCursor ? undefined : handleMouseEnter}
    >
      {!hideBackground ? (
        <Hidden mdDown>
          <Particles className={classes.particles} params={params} />
        </Hidden>
      ) : null}
      <TechnicalHelpContact />
      <Box className={classes.content}>{children}</Box>
    </Box>
  );
};

export default Body;
