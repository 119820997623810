import React, { ReactElement, ReactNode, useState, useCallback, useRef, useEffect, MutableRefObject } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { DropDown, colors } from '@novozymes/components';
import { signOut } from '@novozymes/auth';
import {
  Popper,
  Grow,
  Paper,
  Typography,
  MenuList,
  MenuItem,
  ClickAwayListener,
  Hidden,
  Box,
  makeStyles,
} from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import { History } from 'history';
import { AuthSession } from '../types';
import profileInfoState from '../state/profileInfoState';
import { useTranslation } from 'react-i18next';
import authSessionState from 'state/authSessionState';
import { ProfileValues } from 'pages/Profile';
import zIndexes from '../theme/zIndexes';
import { getUserId } from '../utils/getUserId';
import { getUserProfile } from 'utils/getUserProfile';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0rem 2rem',
    width: 'calc(100% - 4rem)',
    backgroundColor: theme.palette.primary.main,
    height: '3.75rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: zIndexes.medium,
    [theme.breakpoints.down('xs')]: {
      height: '2.75rem',
    },
  },
  logo: {
    width: '7rem',
    [theme.breakpoints.down('xs')]: {
      width: '3.875rem',
    },
  },
  logoContainer: {
    cursor: 'pointer',
    width: '45%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  optionsContainer: {
    cursor: 'pointer',
    width: '45%',
    display: 'flex',
    justifyContent: 'flex-end',
    zIndex: zIndexes.low,
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    lineHeight: '1.75rem',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      lineHeight: '1.25rem',
    },
  },
  user: {
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'center',
  },
  userName: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  dropDownIcon: {
    marginLeft: '0.5rem',
  },
  menuList: {
    padding: '0.125rem 0',
  },
  menuItem: {
    fontSize: '0.75rem',
    lineHeight: '1.5rem',
    borderRadius: '0.5rem',
    height: '1.5rem',
    minHeight: '1.5rem',
    width: 'calc(100% - 2rem)',
    margin: '0.5rem auto',
    '&:hover': {
      backgroundColor: colors.softGreen,
    },
  },
  popperWrapper: {
    borderRadius: '1rem',
    width: '14.5rem',
    marginTop: '0.5rem',
    padding: '0.5rem 0',
  },
}));

interface UserMenuProps {
  open: boolean;
  anchorRef: MutableRefObject<null>;
  onClose: () => void;
  onSignOut: () => void;
  history: History;
}

const UserMenu = (props: UserMenuProps): ReactElement => {
  const { open, anchorRef, onClose, onSignOut } = props;
  const authSession = useRecoilValue<AuthSession | null>(authSessionState);
  const { t } = useTranslation('userMenu');
  const classes = useStyles();

  const handleProfileClick = () => {
    const userId = getUserId(authSession!);
    const user = getUserProfile(authSession!);
    // hasTSNowPortalAccess is a string representing a boolean
    if (user.hasTSNowPortalAccess !== 'false') {
      window.open(`${process.env.REACT_APP_SALESFORCE_COMMUNITY_URL}/s/settings/${userId}`, 'popUpWindow');
    } else {
      window.open(`${process.env.REACT_APP_SALESFORCE_COMMUNITY_URL}/s/customprofile`, 'popUpWindow');
    }
    onClose();
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      placement="bottom-end"
      transition
      disablePortal
      data-testid="userMenuPopper"
    >
      {({ TransitionProps }): ReactElement => (
        <Grow {...TransitionProps}>
          <Paper className={classes.popperWrapper}>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList className={classes.menuList}>
                <MenuItem data-testid="profile-button" className={classes.menuItem} onClick={handleProfileClick}>
                  {t('profile')}
                </MenuItem>
                <MenuItem data-testid="logout-button" className={classes.menuItem} onClick={onSignOut}>
                  {t('logout')}
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

interface HeaderProps extends RouteComponentProps {
  children?: ReactNode;
}

const Header = ({ history }: HeaderProps): ReactElement => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef(null);
  const profileInfo: ProfileValues = useRecoilValue<ProfileValues>(profileInfoState);
  const authSession = useRecoilValue<AuthSession | null>(authSessionState);
  const [fullName, setFullName] = useState('');

  useEffect(() => {
    const givenName = authSession?.idToken.payload.given_name || '';
    const familyName = authSession?.idToken.payload.family_name || '';
    setFullName(`${profileInfo.FirstName || givenName} ${profileInfo.LastName || familyName}`);
  }, [profileInfo, authSession]);

  const handleLogoClick = () => {
    history.push('/');
  };

  const handleMenuOpen = useCallback(() => {
    setMenuOpen(true);
  }, []);
  const handleMenuClose = useCallback(() => {
    setMenuOpen(false);
  }, []);

  return (
    <Box className={classes.container}>
      <Box data-testid="novozymes-logo" className={classes.logoContainer} onClick={handleLogoClick}>
        <img id="logo" className={classes.logo} src="/novozymes.svg" alt="Novozymes" />
      </Box>
      <Box data-testid="mynovozymes-title" className={classes.title} id="my-first-step" onClick={handleLogoClick}>
        MyNovozymes
      </Box>
      <Box className={classes.optionsContainer}>
        <Box id="user-menu" data-testid="user-menu" onClick={handleMenuOpen} className={classes.user}>
          <Hidden xsDown>
            <Typography className={classes.userName} ref={anchorRef}>{`${fullName}`}</Typography>
            <DropDown className={classes.dropDownIcon} />
          </Hidden>
          <Hidden smUp>
            <img alt="profile icon" src="/profileIcon.svg" ref={anchorRef} />
          </Hidden>
        </Box>
      </Box>
      <UserMenu history={history} open={menuOpen} anchorRef={anchorRef} onClose={handleMenuClose} onSignOut={signOut} />
    </Box>
  );
};

export default withRouter(Header);
