import { PortalApp } from 'types';
import { UserProfile } from './getUserProfile';

const technicalServices: PortalApp = {
  id: 'technicalServices',
  icon: '/icons/techservices.svg',
  getUrl: () => `${process.env.REACT_APP_SALESFORCE_COMMUNITY_URL}`,
};

const academy: PortalApp = {
  id: 'academy',
  icon: '/icons/book-open.svg',
  getUrl: () => 'https://academy.novozymes.com',
  /*getUrl: () =>
    `${process.env.REACT_APP_SALESFORCE_COMMUNITY_URL}/services/oauth2/authorize?client_id=${
      process.env.REACT_APP_SALESFORCE_CLIENT_ID_ACADEMY
    }&response_type=code&scope=openid&redirect_uri=${encodeURIComponent(`${process.env.REACT_APP_ACADEMY_URL}`)}`, */
};

const novozymesTv: PortalApp = {
  id: 'novozymesTv',
  icon: '/icons/novozymestv.svg',
  getUrl: () => `http://novozymes.tv`,
};

const market: PortalApp = {
  id: 'market',
  icon: '/icons/market-icon.svg',
  getUrl: () => {
    const referrer = window.location.hostname;
    return `${process.env.REACT_APP_MARKET_URL}?sc_lang=${localStorage.getItem('i18nextLng')}&referrer=${referrer}`;
  },
};

// const starchRefineryOptimizer: PortalApp = {
//   id: 'starchRefineryOptimizer',
//   getUrl: () => `${process.env.REACT_APP_STARCH_REF_OPT_URL}`,
// };

const smartbake: PortalApp = {
  id: 'smartbake',
  getUrl: () => `${process.env.REACT_APP_SMARTBAKE_URL}`,
};

const demoApp: PortalApp = {
  id: 'demoApp',
  getUrl: () => '#',
};

const bioenergyUniversity: PortalApp = {
  id: 'bioenergyUniversity',
  icon: '/icons/book-open.svg',
  getUrl: () => `${process.env.REACT_APP_BIO_UNI_URL}`,
};

export const getUserFeaturedApps = (profile: UserProfile): PortalApp[] => {
  const apps: PortalApp[] = [];

  // hasTSNowPortalAccess is a salesforce attribute and it is a string
  if (profile.hasTSNowPortalAccess === 'true') {
    apps.push(technicalServices);
  }

  if (profile.industrySubGroup?.some((subIndustry) => subIndustry === 'bioenergy')) {
    apps.push(market, bioenergyUniversity, novozymesTv);
  } else if (
    profile.industrySubGroup?.some((subIndustry) => subIndustry === 'baking' || subIndustry === 'hhc laundry')
  ) {
    apps.push(market, academy, novozymesTv);
  } else {
    apps.push(market, novozymesTv);
  }

  return apps;
};

export const getUserAdvancedApps = (profile: UserProfile): PortalApp[] => {
  // Temporary for demo
  if (profile.email === 'helenbelshaw@hotmail.com') {
    return [demoApp];
  }

  if (profile.industrySubGroup?.some((subIndustry) => subIndustry === 'baking')) return [smartbake];

  return [];
};
