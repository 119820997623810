import React, { ReactElement } from 'react';
import { Box, Modal, Backdrop, makeStyles, Typography, MobileStepper } from '@material-ui/core';
import { Button, Cancel, colors } from '@novozymes/components';
import { useTranslation } from 'react-i18next';
import { TourStep } from '../../utils/tourSteps';
import { FormattedDescription } from './IntroTooltip';
import SwipeableViews from 'react-swipeable-views';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    textAlign: 'center',
    paddingTop: '4rem',
    outline: 'none',
    margin: '0',
    position: 'fixed',
    bottom: '0rem',
    borderRadius: '0rem',
    borderTopRightRadius: '2.5rem',
    borderTopLeftRadius: '2.5rem',
    height: 'calc(100% - 4rem)',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    maxHeight: '32.5rem',
  },
  modalTitle: {
    fontSize: '1.125rem',
    lineHeight: '1.375rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
  description: {
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    paddingLeft: '2.25rem',
    paddingRight: '2.25rem',
    height: '6rem',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: '1rem 2.5rem 2.5rem 0',
  },
  image: {
    width: '100%',
    maxWidth: '20rem',
    padding: '0 4rem',
    margin: '0 auto',
  },
  cancelButton: {
    width: '0.875rem',
    height: '0.875rem',
    cursor: 'pointer',
    top: '2rem',
    right: '2rem',
    position: 'absolute',
  },
  dotContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1rem',
  },
  progressDot: {
    borderRadius: '50%',
    width: '0.75rem',
    height: '0.75rem',
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
    backgroundColor: colors.softGreen,
  },
  currentProgressDot: {
    borderRadius: '50%',
    width: '0.75rem',
    height: '0.75rem',
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
    backgroundColor: colors.primaryGreen,
  },
  mobileStepper: {
    visibility: 'hidden',
  },
}));

interface MobileIntroTourProps {
  currentTourStep: number;
  run: boolean;
  setStep: (step: number) => void;
  setRun: (run: boolean) => void;
  steps: Array<TourStep>;
}

const ProgressDots = (props: { steps: number; currentStep: number }): ReactElement => {
  const classes = useStyles();

  const getDots = () => {
    const dots: Array<ReactElement> = [];
    for (let i = 0; i < props.steps; i++) {
      dots.push(<Box key={i} className={props.currentStep >= i ? classes.currentProgressDot : classes.progressDot} />);
    }
    return dots;
  };

  return <Box className={classes.dotContainer}>{getDots()}</Box>;
};

const MobileIntroTour = (props: MobileIntroTourProps): ReactElement => {
  const classes = useStyles();
  const { run, setRun, steps, setStep, currentTourStep } = props;
  const { t } = useTranslation('intro');

  const handleModalClose = () => {
    setRun(false);
    localStorage.setItem('recurringUser', 'true');
  };

  const handleNext = () => {
    setStep(currentTourStep + 1);
  };

  const handleBack = () => {
    setStep(currentTourStep - 1);
  };

  const handleStepChange = (step: number) => {
    setStep(step);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      data-testId="mobile-intro-tour-modal"
      open={run}
      onClose={handleModalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Box className={classes.paper}>
        <>
          <Cancel className={classes.cancelButton} onClick={handleModalClose} />
          <SwipeableViews index={currentTourStep} onChangeIndex={handleStepChange} enableMouseEvents>
            {steps.map((step, idx) => {
              return (
                <>
                  {Math.abs(currentTourStep - idx) <= 2 ? (
                    <>
                      <Box>
                        <Typography className={classes.modalTitle} id="transition-modal-title">
                          {step.title}
                        </Typography>
                        {step.content.keyword ? (
                          <FormattedDescription
                            description={step.content.description}
                            keyword={step.content.keyword}
                            className={classes.description}
                          />
                        ) : (
                          <Typography className={classes.description} id="transition-modal-description">
                            {step.content.description}
                          </Typography>
                        )}
                      </Box>
                      <img className={classes.image} alt="" src={step.content.imgUrl} />
                    </>
                  ) : null}
                </>
              );
            })}
          </SwipeableViews>
          <MobileStepper
            className={classes.mobileStepper}
            steps={0}
            activeStep={currentTourStep}
            nextButton={null}
            backButton={null}
          />
          <Box>
            <ProgressDots steps={steps.length} currentStep={currentTourStep} />
          </Box>
          <Box className={classes.buttonContainer}>
            {currentTourStep > 0 ? (
              <Button type="secondary" onClick={handleBack}>
                {t('tooltip.back-button')}
              </Button>
            ) : null}
            {currentTourStep < steps.length - 1 ? (
              <Button onClick={handleNext}>{t('tooltip.next-button')}</Button>
            ) : (
              <Button onClick={handleModalClose}>{t('tooltip.finish-button')}</Button>
            )}
          </Box>
        </>
      </Box>
    </Modal>
  );
};

export default MobileIntroTour;
