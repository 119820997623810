import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslations from 'languages/en.json';
import zhTranslations from 'languages/zh.json';
import esTranslations from 'languages/es.json';
import ptTranslations from 'languages/pt.json';
import deTranslations from 'languages/de.json';
import frTranslations from 'languages/fr.json';
import jpTranslations from 'languages/jp.json';

export const supportedLanguages = [
  { key: 'en', label: 'English' },
  { key: 'zh', label: 'Chinese' },
  { key: 'de', label: 'German' },
  { key: 'fr', label: 'French' },
  { key: 'es', label: 'Spanish' },
  { key: 'pt', label: 'Portugese' },
  { key: 'jp', label: 'Japanese' },
];

export const translations = {
  en: enTranslations,
  zh: zhTranslations,
  es: esTranslations,
  pt: ptTranslations,
  de: deTranslations,
  fr: frTranslations,
  jp: jpTranslations,
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: translations,
    load: 'languageOnly',
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      wait: true,
    },
  });

export default i18n;
