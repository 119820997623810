import React, { ReactElement, useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AuthEvent, validateState } from '@novozymes/auth';
import TagManager from 'react-gtm-module';
import authConfig from 'authConfig';
import Page from 'layout/Page';
import Welcome from 'pages/Welcome';
import Profile from 'pages/Profile';
import { Amplify } from '@aws-amplify/core';
import { useRecoilState, useRecoilValue } from 'recoil';
import { initializeCookieConsent } from '@novozymes/utils';
import authSessionState, { userFeaturedAppsState } from 'state/authSessionState';
import Auth from '@aws-amplify/auth';
import { Hidden } from '@material-ui/core';
import { Snackbar, Alert } from 'components/Toaster';
import { useTranslation } from 'react-i18next';
import Joyride, { CallBackProps, TooltipRenderProps } from 'react-joyride';
import IntroModal from './components/intro/IntroModal';
import IntroTooltip from './components/intro/IntroTooltip';
import MobileIntroTour from './components/intro/MobileIntroTour';
import { getTourSteps } from './utils/tourSteps';
import { datadogLogger, DatadogConfig, initializeGTM } from '@novozymes/utils';
import { AuthSession } from 'types';
import i18n from 'i18next';

import Redirecter from 'Redirecter';
import { getUserProfile } from 'utils/getUserProfile';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      'authentication-wrapper': any;
    }
  }
}

Amplify.configure(authConfig);

const dataDogConfig: DatadogConfig = {
  clientToken: process.env.REACT_APP_DATADOG_TOKEN || '',
  site: 'datadoghq.com',
  forwardErrorsToLogs: false,
  sampleRate: 100,
  service: 'customer-portal',
  env: process.env.REACT_APP_STAGE,
};

const joyrideStyles = {
  spotlight: {
    backgroundColor: 'transparent',
  },
};

function App(): ReactElement {
  const [authSession, setAuthSession] = useRecoilState<AuthSession | null>(authSessionState);
  const [showUserRequestFail, setShowUserRequestFail] = useState(false);
  const { t } = useTranslation('global');
  const [runWelcomeTour, setRunWelcomeTour] = useState(false);
  const [firstTimeUser, setFirstTimeUser] = useState(false);
  const [currentTourStep, setCurrentTourStep] = useState<number>(0);
  const [authStateValidated, setAuthStateValidated] = useState(false);
  const [authRedirect, setAuthRedirect] = useState<string | null | undefined>();
  const userFeaturedApps = useRecoilValue(userFeaturedAppsState);
  const [userProfile, setUserProfile] = useState({});

  const tourSteps = getTourSteps(t, userFeaturedApps);

  useEffect(() => {
    initializeCookieConsent();
    datadogLogger.init(dataDogConfig);

    initializeGTM('GTM-K83D567');
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const langParam = searchParams.get('lang');
    if (langParam) {
      i18n.changeLanguage(langParam);
      localStorage.setItem('i18nextLng', langParam);
    }

    // Make sure to redirect to the requested page before Authentication
    const redirect = validateState();
    setAuthRedirect(redirect);

    if (!!redirect) {
      setAuthStateValidated(true);
    }

    const recurringUser = localStorage.getItem('recurringUser');
    !recurringUser && setFirstTimeUser(true);
  }, []);

  const onAuthEvent = useCallback(
    async (event) => {
      const authEvent = event as AuthEvent;
      if (authEvent.detail.newAuthState === 'signedIn') {
        const authSession = ((await Auth.currentSession()) as unknown) as AuthSession;
        setAuthSession(authSession);

        const userProfile = getUserProfile(authSession);
        setUserProfile(userProfile);
      }
    },
    [setAuthSession]
  );
  // eslint-disable-next-line no-undef
  window.addEventListener('AuthStateChange', onAuthEvent);

  const handleJoyrideCallback = (data: CallBackProps) => {
    if (
      data.action === 'close' ||
      (data.action === 'next' && data.index === data.size - 1 && data.type === 'step:after')
    ) {
      setRunWelcomeTour(false);
      localStorage.setItem('recurringUser', 'true');
    } else if (data.action === 'next' && data.index < data.size - 1 && data.type === 'step:after') {
      setCurrentTourStep(data.index + 1);
    } else if (data.action === 'prev' && data.type === 'step:after') {
      setCurrentTourStep(data.index - 1);
    }
  };

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        ...userProfile,
      },
    });
  }, [userProfile]);

  return (
    <>
      <Router>
        <Switch>
          <authentication-wrapper id="authentication" amplifyConfig={JSON.stringify(authConfig)}>
            {authSession && authStateValidated && authRedirect ? (
              <Redirecter redirectTo={authRedirect}>
                <>
                  {firstTimeUser ? <IntroModal runWelcomeTour={setRunWelcomeTour} /> : null}
                  <Hidden xsDown>
                    <Joyride
                      styles={joyrideStyles}
                      floaterProps={{
                        disableAnimation: true,
                      }}
                      tooltipComponent={IntroTooltip as React.ElementType<TooltipRenderProps>}
                      callback={handleJoyrideCallback}
                      disableScrolling
                      continuous
                      run={runWelcomeTour}
                      steps={tourSteps}
                      showProgress
                      stepIndex={currentTourStep}
                      showSkipButton
                    />
                  </Hidden>
                  <Hidden smUp>
                    <MobileIntroTour
                      currentTourStep={currentTourStep}
                      setStep={setCurrentTourStep}
                      run={runWelcomeTour}
                      setRun={setRunWelcomeTour}
                      steps={tourSteps}
                    />
                  </Hidden>
                  <Route exact path="/">
                    <>
                      <Page disableCursor>
                        <Welcome />
                      </Page>
                    </>
                  </Route>
                  <Route path="/profile">
                    <>
                      <Page hideBackground disableCursor>
                        <Profile />
                      </Page>
                    </>
                  </Route>
                </>
              </Redirecter>
            ) : null}
          </authentication-wrapper>
          <Route path="/public">
            <p>Public page without authentication</p>
          </Route>
        </Switch>
      </Router>
      <Snackbar open={showUserRequestFail} autoHideDuration={5000} onClose={() => setShowUserRequestFail(false)}>
        <Alert onClose={() => setShowUserRequestFail(false)} severity="error">
          {t('getProfileFailMessage')}
        </Alert>
      </Snackbar>
    </>
  );
}

export default App;
