import React, { ReactElement } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import MainAppCard from 'components/MainAppCard';
import AppCard from 'components/AppCard';
import { PortalApp } from 'types';
import { useTranslation } from 'react-i18next';
import { colors } from '@novozymes/components';
import { useRecoilValue } from 'recoil';
import { userAdvancedAppsState, userFeaturedAppsState } from 'state/authSessionState';

const useStyles = makeStyles((theme) => ({
  digitalToolsApps: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '4rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '4rem',
    },
  },
  goToDigitalTools: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'top',
  },
  digitalToolsHeader: {
    fontSize: '2rem',
    textAlign: 'center',
    fontWeight: 'bold',
    color: colors.white95,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  MainApps: {
    padding: '0 10rem',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '0 2rem',
    },
  },
  welcomeTitle: {
    color: colors.white95,
    fontSize: '3.5rem',
    lineHeight: '4.125rem',
    fontWeight: 'bold',
    marginLeft: '10rem',
    width: '36rem',
    marginTop: '8rem',
    marginBottom: '8rem',
    cursor: 'default',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
      textAlign: 'center',
      width: '100%',
      margin: '4rem auto',
      lineHeight: '2.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
}));

const IndustryApps = (): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation('welcome');
  const userfeaturedApps = useRecoilValue(userFeaturedAppsState);
  const userAdvancedApps = useRecoilValue(userAdvancedAppsState);

  return (
    <>
      <Box className={classes.welcomeTitle} data-testId="welcome-title">
        {t('welcomeTitle')}
      </Box>

      <Box className={classes.MainApps}>
        {userfeaturedApps.map((app) => (
          <MainAppCard key={app.id} app={app} />
        ))}
      </Box>

      {userAdvancedApps.length && <Box className={classes.digitalToolsHeader}>{t('digitalToolsHeading')}</Box>}
      <Box mb={5}>
        <Grid container className={classes.digitalToolsApps} alignItems="center">
          {userAdvancedApps.map((appData: PortalApp) => (
            <Grid key={appData.id} item>
              <AppCard app={appData} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default IndustryApps;
