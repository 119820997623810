import { AuthSession } from 'types';

export type UserProfile = Partial<{
  contactId: string;
  accountType: string;
  region: string;
  industrySubGroup: string[];
  isInternal: string;
  email: string;
  name: string;
  hasTSNowPortalAccess: string;
}>;

export const getUserProfile = (authSession: AuthSession): UserProfile => {
  const { email, name, profile } = authSession.idToken.payload;
  if (!profile) {
    return { email, name };
  }
  const parsedProfile = JSON.parse(profile);

  return {
    ...parsedProfile,
    industrySubGroup: parsedProfile.industrySubGroup?.split(';').map((sub: string) => sub.toLowerCase()),
    email,
    name,
  };
};
