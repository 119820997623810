import React, { ReactElement } from 'react';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import MuiSnackbar, { SnackbarProps } from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core';
import { colors } from '@novozymes/components';

const getColor = (severity?: Severity): string => {
  switch (severity) {
    case 'error':
      return colors.errorRed;
    case 'success':
      return colors.primaryGreenHover;
    default:
      return colors.black;
  }
};

const useStyles = makeStyles(() => ({
  alert: (props: AlertProps) => ({
    width: '100%',
    height: '2rem',
    textAlign: 'center',
    borderRadius: 0,
    backgroundColor: getColor(props.severity),
    '& .MuiAlert-message': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '0.75rem',
    },
  }),
  snackbar: {
    width: '100%',
    position: 'absolute',
    top: '7.5rem',
    height: '2rem',
  },
}));

type Severity = 'success' | 'info' | 'warning' | 'error';

export const Alert = (props: AlertProps): ReactElement => {
  const classes = useStyles(props);
  return <MuiAlert className={classes.alert} icon={false} variant="filled" {...props} />;
};

export const Snackbar = (props: SnackbarProps): ReactElement => {
  const classes = useStyles();
  return <MuiSnackbar className={classes.snackbar} {...props} />;
};
