import { TFunction } from 'i18next';
import { Step, Placement } from 'react-joyride';
import { PortalApp } from 'types';

interface TourStepContent {
  description: string;
  keyword?: string;
  imgUrl: string;
}

export interface TourStep extends Step {
  content: TourStepContent;
}

export const getTourSteps = (t: TFunction, apps: PortalApp[]): Array<TourStep> => {
  const tourStepsForApp = {
    technicalServices: {
      title: t('tourSteps.step1.title'),
      target: '#technicalServices',
      content: {
        description: t('tourSteps.step1.description'),
        keyword: t('tourSteps.step1.keyword'),
        imgUrl: 'ts_intro.svg',
      },
      disableBeacon: true,
      placement: 'left' as Placement,
    },
    market: {
      title: t('tourSteps.step2.title'),
      target: '#market',
      content: {
        description: t('tourSteps.step2.description'),
        keyword: t('tourSteps.step2.keyword'),
        imgUrl: 'market_intro.svg',
      },
      disableBeacon: true,
      placement: 'bottom-start' as Placement,
    },
    novozymesTv: {
      title: t('tourSteps.step3.title'),
      target: '#novozymesTv',
      content: {
        description: t('tourSteps.step3.description'),
        keyword: t('tourSteps.step3.keyword'),
        imgUrl: 'novozymes_tv_intro.svg',
      },
      disableBeacon: true,
      placement: 'bottom-start' as Placement,
    },
  };

  const appTourSteps = apps.reduce((acc, app) => {
    const appStep = tourStepsForApp[app.id];
    if (appStep) {
      acc.push(appStep);
    }
    return acc;
  }, [] as any[]);

  return [
    ...appTourSteps,
    {
      title: t('tourSteps.step4.title'),
      target: '#user-menu',
      content: {
        description: t('tourSteps.step4.description'),
        imgUrl: 'profile_intro.svg',
      },
      disableBeacon: true,
      placement: 'bottom-end' as Placement,
    },
  ];
};
