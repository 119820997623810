import React, { ReactElement, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { NzForm } from '@novozymes/components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import profileInfoState from '../state/profileInfoState';
import { updateProfileInfo } from 'utils/profileInfo';
import { useTranslation } from 'react-i18next';
import { getUserId } from 'utils/getUserId';
import authSessionState from 'state/authSessionState';
import { getAuthHeaders } from 'utils/getAuthHeaders';
import { Snackbar, Alert } from 'components/Toaster';
import { datadogLogger } from '@novozymes/utils';
import { TFunction } from 'i18next';
import { AuthSession } from 'types';

type FormField = {
  name: string;
  label: string;
  type?: string;
};

export const getFormFields = (t: TFunction): FormField[] => {
  return [
    {
      name: 'FirstName',
      label: t('fields.firstName'),
    },
    {
      name: 'LastName',
      label: t('fields.lastName'),
    },
    {
      name: 'Email',
      label: t('fields.email'),
      type: 'email',
    },
    {
      name: 'Phone',
      label: t('fields.phone'),
    },
    {
      name: 'MobilePhone',
      label: t('fields.mobilePhone'),
    },
    {
      name: 'CompanyName',
      label: t('fields.companyName'),
    },
  ];
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: 'calc(100% - 6.25rem)',
    textAlign: 'center',
    padding: '0 2rem',
    marginBottom: '3rem',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  title: {
    fontSize: '1.5rem',
    lineHeight: '3.125rem',
    marginTop: '2rem',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '3rem',
  },
}));

export interface ProfileValues {
  FirstName: string;
  LastName: string;
  Email: string;
  CompanyName: string;
  Phone: string;
  MobilePhone: string;
}

const Profile = (): ReactElement => {
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const [showSaveFail, setShowSaveFail] = useState(false);
  const profileInfo: ProfileValues = useRecoilValue<ProfileValues>(profileInfoState);
  const setProfileInfo = useSetRecoilState(profileInfoState);
  const authSession = useRecoilValue<AuthSession | null>(authSessionState);

  const classes = useStyles();
  const { t } = useTranslation('profileInfoForm');

  const updateProfileValues = (values: ProfileValues) => {
    const userId = getUserId(authSession!);
    updateProfileInfo(userId, values, getAuthHeaders(authSession!))
      .then(() => {
        setProfileInfo(values);
        setShowSaveSuccess(true);
      })
      .catch((error) => {
        datadogLogger.logError('Failed to update profile info', { error });
        setShowSaveFail(true);
      });
  };

  const formValidation = (values: ProfileValues) => {
    let errors: Record<string, string> = {};
    if (values.Email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)) {
      errors = { ...errors, Email: t('invalidEmailMessage') };
    }
    if (!/^([^0-9]*)$/.test(values.FirstName)) {
      errors = { ...errors, FirstName: t('invalidFirstNameMessage') };
    }
    if (!/^([^0-9]*)$/.test(values.LastName)) {
      errors = { ...errors, LastName: t('invalidLastNameMessage') };
    }
    return errors;
  };

  const formFields = getFormFields(t);

  return (
    <Box className={classes.container} data-testid="profile-form-container">
      <Box className={classes.title}>{t('title')}</Box>
      <NzForm
        fields={formFields}
        formValidation={formValidation}
        initialValues={profileInfo}
        submitForm={updateProfileValues}
      />
      <Snackbar open={showSaveSuccess} autoHideDuration={5000} onClose={() => setShowSaveSuccess(false)}>
        <Alert onClose={() => setShowSaveSuccess(false)} severity="success">
          {t('updateProfileSuccessMessage')}
        </Alert>
      </Snackbar>
      <Snackbar open={showSaveFail} autoHideDuration={5000} onClose={() => setShowSaveFail(false)}>
        <Alert onClose={() => setShowSaveFail(false)} severity="error">
          {t('updateProfileFailMessage')}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Profile;
