import React, { ReactElement, useState } from 'react';
import { Box, Modal, Backdrop, makeStyles, Typography, Hidden } from '@material-ui/core';
import { Button, Cancel } from '@novozymes/components';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    maxWidth: '40rem',
    borderRadius: '2.5rem',
    textAlign: 'center',
    paddingTop: '4rem',
    outline: 'none',
    margin: '0 1rem',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      margin: '0',
      position: 'fixed',
      bottom: '0rem',
      borderRadius: '0rem',
      borderTopRightRadius: '2.5rem',
      borderTopLeftRadius: '2.5rem',
      maxHeight: '90%',
    },
  },
  welcomeModalTitle: {
    fontSize: '2rem',
    lineHeight: '2.5rem',
    fontWeight: 'bold',
    marginBottom: '1.625rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.125rem',
      marginBottom: '1rem',
    },
  },
  welcomeModalDescription: {
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    marginBottom: '2.125rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
    marginBottom: '2rem',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      textAlign: 'center',
    },
  },
  image: {
    height: '10.25rem',
    [theme.breakpoints.down('xs')]: {
      height: '7rem',
    },
  },
  cancelButton: {
    width: '0.875rem',
    height: '0.875rem',
    cursor: 'pointer',
    top: '2rem',
    right: '2rem',
    position: 'absolute',
  },
  exploreButtonWrapper: {
    marginBottom: '1rem',
  },
}));

interface IntroModalProps {
  runWelcomeTour: (value: boolean) => void;
}

const IntroModal = (props: IntroModalProps): ReactElement => {
  const [tourModalOpen, setTourModalOpen] = useState<boolean>(true);
  const classes = useStyles();
  const { runWelcomeTour } = props;
  const { t } = useTranslation('intro');

  const handleExploreClick = () => {
    setTourModalOpen(false);
    runWelcomeTour(true);
  };

  const handleModalClose = () => {
    setTourModalOpen(false);
    localStorage.setItem('recurringUser', 'true');
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      data-testid="intro-modal"
      className={classes.modal}
      open={tourModalOpen}
      onClose={handleModalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <>
        <div className={classes.paper}>
          <Cancel className={classes.cancelButton} onClick={handleModalClose} />
          <Typography className={classes.welcomeModalTitle} id="transition-modal-title">
            {t('modal.welcome-title')}
          </Typography>
          <Typography className={classes.welcomeModalDescription} id="transition-modal-description">
            {t('modal.description')}
          </Typography>
          <img className={classes.image} alt="" src="/introTour.svg" />
          <Box className={classes.buttonContainer}>
            <Hidden xsDown>
              <Button type="secondary" onClick={handleModalClose}>
                {t('modal.skip')}
              </Button>
              <Button data-testId="explore-button" onClick={() => handleExploreClick()}>
                {t('modal.explore-button')}
              </Button>
            </Hidden>
            <Hidden smUp>
              <>
                <Box className={classes.exploreButtonWrapper}>
                  <Button data-testId="explore-button" onClick={() => handleExploreClick()}>
                    {t('modal.explore-button')}
                  </Button>
                </Box>
                <Box>
                  <Button type="tertiary" onClick={handleModalClose}>
                    {t('modal.skip')}
                  </Button>
                </Box>
              </>
            </Hidden>
          </Box>
        </div>
      </>
    </Modal>
  );
};

export default IntroModal;
