import React, { ReactElement, useState, useEffect, useRef } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Header from './Header';
import Menu from './Menu';
import Body from './Body';
import ErrorBoundary from '../components/ErrorBoundary';
import i18n from 'i18next';
import { translations, supportedLanguages } from '../i18n';
import { Footer, LanguageSelect } from '@novozymes/components';
import zIndexes from '../theme/zIndexes';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundColor: '#FFF',
  },
}));

interface PageProps {
  children?: ReactElement;
  hideBackground?: boolean;
  disableCursor?: boolean;
}

const languages = Object.keys(translations);

const Page = (props: PageProps): ReactElement => {
  const classes = useStyles();
  const { children, hideBackground, disableCursor } = props;

  const [language, setLanguage] = useState<string>('');
  const anchorRef = useRef(null);

  const handleLanguageSelect = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem('i18nextLng', language);
    setLanguage(language);
  };

  useEffect(() => {
    const localStoredLanguage = localStorage.getItem('i18nextLng');
    let initialLanguage: string;
    if (localStoredLanguage && languages.includes(localStoredLanguage)) initialLanguage = localStoredLanguage;
    else if (languages.includes(window.navigator.language)) initialLanguage = window.navigator.language;
    else initialLanguage = 'en';

    handleLanguageSelect(initialLanguage);
  }, []);

  return (
    <>
      <Box className={classes.wrapper}>
        <Header />
        <Menu />
        <ErrorBoundary>
          <Body hideBackground={hideBackground} disableCursor={disableCursor}>
            {children}
          </Body>
        </ErrorBoundary>
      </Box>
      <Footer>
        <LanguageSelect
          handleLanguageSelect={handleLanguageSelect}
          languages={languages}
          supportedLanguages={supportedLanguages}
          anchorRef={anchorRef}
          currentLanguage={language}
          zIndex={zIndexes.high}
        />
      </Footer>
    </>
  );
};

export default Page;
