import { ProfileValues } from 'pages/Profile';
import { atom } from 'recoil';

const profileInfoState = atom<ProfileValues>({
  key: 'profileInfoState',
  default: {
    FirstName: '',
    LastName: '',
    Email: '',
    Phone: '',
    CompanyName: '',
    MobilePhone: '',
  },
});

export default profileInfoState;
