import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { datadogLogger } from '@novozymes/utils';
import { Button, colors } from '@novozymes/components';
import { WithTranslation, withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

const styles = createStyles({
  errorContainer: {
    textAlign: 'center',
    paddingTop: '1.5rem',
    width: '100%',
  },
  wrapper: {
    maxWidth: '44rem',
    margin: '0 auto',
  },
  title: {
    marginBottom: '2rem',
  },
  description: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    margin: '0 2rem 2rem 2rem',
  },
  homeButton: {
    fontSize: '1.125rem',
    textDecoration: 'underline',
    textDecorationColor: colors.primaryGreen,
    lineHeight: '1.5rem',
    color: colors.black,
    marginTop: '2rem',
  },
  homeLink: {
    textDecoration: 'none',
  },
  errorImg: {
    marginTop: '2rem',
  },
});

interface ErrorBoundaryProps extends WithTranslation, WithStyles<typeof styles> {
  t: TFunction;
}

interface ErrorBoundaryState {
  error: Error | null;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error) {
    datadogLogger.logError('Unhandled error', error);
  }

  render() {
    const { classes, t } = this.props;
    if (this.state.error) {
      return (
        <Box className={classes.errorContainer}>
          <Box className={classes.wrapper}>
            <Typography variant="h2" className={classes.title}>
              {t('errorBoundaryTitle')}
            </Typography>
            <Typography variant="body1" className={classes.description}>
              {t('errorBoundaryDescription')}
            </Typography>
            <Box>
              <a className={classes.homeLink} href={process.env.REACT_APP_DOMAIN}>
                <Button type="secondary">{t('errorBoundaryHomeButton')}</Button>
              </a>
            </Box>
            <img alt="" src="error_img.svg" className={classes.errorImg} />
          </Box>
        </Box>
      );
    }
    return this.props.children;
  }
}

export default withTranslation('global')(withStyles(styles)(ErrorBoundary));
