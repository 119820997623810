import axios, { AxiosResponse } from 'axios';
import { ProfileValues } from 'pages/Profile';

export const getProfileInfo = async (userId: string, headers: { [key: string]: string }): Promise<AxiosResponse> =>
  axios.get(`${process.env.REACT_APP_API_HOST}/salesforce/user/${userId}`, { headers });

export const updateProfileInfo = async (
  userId: string,
  profileData: ProfileValues,
  headers: { [key: string]: string }
): Promise<AxiosResponse> =>
  axios.patch(`${process.env.REACT_APP_API_HOST}/salesforce/user/${userId}`, profileData, { headers });
