import React, { ReactElement } from 'react';
import { Box, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { PortalApp } from 'types';
import { colors } from '@novozymes/components';
import zIndexes from '../theme/zIndexes';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '15rem',
    maxWidth: '15rem',
    minWidth: '12rem',
    textAlign: 'center',
    color: colors.white95,
    zIndex: zIndexes.medium,
  },
  content: {
    height: '10.625rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      padding: 0,
    },
  },
  actions: {
    paddingLeft: 32,
    paddingRight: 32,
    paddingBottom: 32,
    paddingTop: 0,
    height: '9.375rem',
    display: 'flex',
    justifyContent: 'center',
  },
  description: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    textAlign: 'center',
    margin: '2rem 0 1rem 0',
    color: colors.white95,
    height: '2.5rem',
  },
  appIcon: {
    height: '1.3125rem',
    width: '1.3125rem',
  },
  appLink: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
  },
  appName: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    marginTop: '1rem',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    marginBottom: '-1rem',
    color: colors.white95,
  },
}));

interface MainAppCardProps {
  app: PortalApp;
}

const MainAppCard = ({ app }: MainAppCardProps): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation('apps');

  return (
    <Box className={classes.root} data-testid="main-app-card" id={`${app.id}AppCard`}>
      <a
        className={classes.appLink}
        target="_blank"
        rel="noopener noreferrer"
        href={app.getUrl()}
        data-testid="main-app-card-link"
      >
        <CardContent className={classes.content}>
          <Typography variant="body1" component="p" className={classes.appName} data-testid="main-app-name">
            {t(`${app.id}.name`)}
          </Typography>
          <Typography variant="body1" component="p" className={classes.description}>
            {t(`${app.id}.description`)}
          </Typography>
          <img className={classes.appIcon} src={app.icon} alt={app.id} />
        </CardContent>
      </a>
    </Box>
  );
};

export default MainAppCard;
