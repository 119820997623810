import { atom, selector } from 'recoil';
import { AuthSession, PortalApp } from 'types';
import { getUserFeaturedApps, getUserAdvancedApps } from 'utils/appDirectory';
import { getUserProfile } from 'utils/getUserProfile';

const authSessionState = atom<AuthSession | null>({
  key: 'authSessionState',
  default: null,
});

export const userFeaturedAppsState = selector<PortalApp[]>({
  key: 'userFeaturedAppsState',
  get: ({ get }) => {
    const authSession = get(authSessionState);

    if (authSession === null) return [];

    const userProfile = getUserProfile(authSession);

    return getUserFeaturedApps(userProfile);
  },
});

export const userAdvancedAppsState = selector<PortalApp[]>({
  key: 'userAdvancedAppsState',
  get: ({ get }) => {
    const authSession = get(authSessionState);

    if (authSession === null) return [];

    const userProfile = getUserProfile(authSession);

    return getUserAdvancedApps(userProfile);
  },
});

export default authSessionState;
