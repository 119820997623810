import { Box, makeStyles, Slide, IconButton, Typography } from '@material-ui/core';
import { Button, colors, Right, OutlinedInputWithLabel } from '@novozymes/components';
import React, { ChangeEvent, Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { datadogLogger } from '@novozymes/utils';
import sendEmailMessage from 'utils/sendEmailMessage';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  buttonWrapper: {
    position: 'absolute',
    top: 16,
    right: 16,
    zIndex: 100,
  },
  slideUpWrapper: {
    position: 'absolute',
    top: 16,
    right: 16,
    zIndex: 110,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '300px',
  },
  formWrapper: {
    backgroundColor: colors.white95,
    flex: 1,
  },
});

const TechnicalHelpContact = (): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation('TechnicalHelpContact');

  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [apiError, setApiError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setEmail('');
    setMessage('');
    setEmailError('');
    setMessageError('');
    setApiError('');
    setIsOpen(false);
    setLoading(false);
  };

  const handleValueUpdate = (setter: Dispatch<SetStateAction<string>>) => (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setter(value);
  };

  const handleSubmit = () => {
    const emailEmpty = email.trim() === '';
    const messageEmpty = message.trim() === '';
    if (emailEmpty) {
      setEmailError(t('emailError'));
    } else {
      setEmailError('');
    }
    if (messageEmpty) {
      setMessageError(t('messageError'));
    } else {
      setMessageError('');
    }

    if (emailEmpty || messageEmpty) {
      return;
    }

    if (!loading) {
      setLoading(true);
      setApiError('');
      sendEmailMessage(email, message)
        .then(() => {
          setLoading(false);
          handleClose();
        })
        .catch((error) => {
          datadogLogger.logError('Error sending feedback message', error);
          setApiError(t('apiError'));
          setLoading(false);
        });
    }
  };

  return (
    <>
      {!isOpen && (
        <Box className={classes.buttonWrapper}>
          <Button
            onClick={() => {
              setIsOpen(true);
            }}
            small
          >
            {t('buttonText')}
          </Button>
        </Box>
      )}
      <Box className={classes.slideUpWrapper}>
        <Slide direction="left" in={isOpen} mountOnEnter unmountOnExit>
          <Box className={classes.formWrapper}>
            <Box pl={2} display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h5"> {t('formHeading')}</Typography>
              <IconButton onClick={handleClose}>
                <Right />
              </IconButton>
            </Box>
            <Box px={2}>
              <Box mb={2}>
                <Typography variant="body2">{t('formIntro')}</Typography>
              </Box>
              <Box mb={2}>
                <OutlinedInputWithLabel
                  value={email}
                  label={t('emailLabel')}
                  error={!!emailError}
                  errorMessage={emailError}
                  handleChange={handleValueUpdate(setEmail)}
                />
              </Box>
              <Box mb={2}>
                <OutlinedInputWithLabel
                  value={message}
                  label={t('messageLabel')}
                  error={!!messageError}
                  errorMessage={messageError}
                  handleChange={handleValueUpdate(setMessage)}
                  multiline
                />
              </Box>
              <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
                <Button small onClick={handleSubmit}>
                  {t('submitButton')}
                </Button>
                <Typography variant="body2" color="error">
                  {apiError}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Slide>
      </Box>
    </>
  );
};

export default TechnicalHelpContact;
