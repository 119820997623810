import React, { ReactElement } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { colors } from '@novozymes/components';
import { useRecoilValue } from 'recoil';
import { userFeaturedAppsState } from 'state/authSessionState';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0rem 1rem',
    width: 'calc(100% - 2rem)',
    backgroundColor: colors.bg3,
    height: '3.75rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
      height: '2.75rem',
    },
  },
  menuItem: {
    color: colors.black,
    cursor: 'pointer',
    fontSize: '1rem',
    fontWeight: 'bold',
    lineHeight: '1.25rem',
    marginLeft: '3rem',
    marginRight: '3rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
      marginLeft: '0.5rem',
      marginRight: '0.5rem',
    },
    '&:hover': {
      color: colors.primaryGreenHover,
    },
  },
  appLink: {
    color: colors.black,
    textDecoration: 'none',
  },
}));

const Menu = (): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation('apps');
  const userFeaturedApps = useRecoilValue(userFeaturedAppsState);

  return (
    <Box className={classes.container}>
      {userFeaturedApps.map((app) => (
        <a
          key={app.id}
          id={`${app.id}MenuLink`}
          data-testid={`${app.id}-link`}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.appLink}
          href={app.getUrl()}
        >
          <Box className={classes.menuItem}>{t(`${app.id}.name`)}</Box>
        </a>
      ))}
    </Box>
  );
};

export default withRouter(Menu);
