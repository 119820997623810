import axios, { AxiosResponse } from 'axios';

const sendEmailMessage = async (userEmail: string, userMessage: string): Promise<AxiosResponse> => {
  return axios.post(
    `${process.env.REACT_APP_SUPPORT_API_HOST}/support`,
    `Email: ${userEmail} \nMessage: ${userMessage}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export default sendEmailMessage;
