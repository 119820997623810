import React, { ReactElement } from 'react';
import { Typography, Box } from '@material-ui/core';
import { Button, colors } from '@novozymes/components';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { PortalApp } from '../types';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '11rem',
    width: '18rem',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.02), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.14)',
    borderRadius: '20px',
    marginBottom: '4rem',
    position: 'relative',
    textAlign: 'left',
    padding: '1.5rem',
    backgroundColor: colors.bg1,
    [theme.breakpoints.down('xs')]: {
      marginBottom: '2rem',
      width: '19rem',
    },
  },
  appName: {
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: '0.5rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
      marginTop: 0,
    },
  },
  description: {
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    textAlign: 'left',
    marginBottom: '1.5rem',
    height: '2rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
      marginTop: 0,
    },
  },
  linkElement: {
    textDecoration: 'none',
    color: 'inherit',
  },
  appButtonContainer: {
    textAlign: 'right',
  },
}));

interface AppCardProps {
  app: PortalApp;
}

const AppCard = ({ app }: AppCardProps): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation('apps');

  return (
    <Box id={`${app.id}AppCard`}>
      <Box className={classes.root} data-testid="app-card">
        <Box className={classes.appName}>{t(`${app.id}.name`)}</Box>
        <Typography variant="body1" component="p" className={classes.description}>
          {t(`${app.id}.description`)}
        </Typography>
        <Box className={classes.appButtonContainer}>
          <a
            className={classes.linkElement}
            target="_blank"
            rel="noopener noreferrer"
            href={app.getUrl()}
            data-testid="app-card-link"
          >
            <Button small>{t('goToAppButton')}</Button>
          </a>
        </Box>
      </Box>
    </Box>
  );
};

export default AppCard;
